import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ApiContext } from "../../Context/ApiContext";
import {
  DeleteAdvertise,
  DeletMultipleAdvert,
  getAllAdvertise,
  updateAdvertiseStatus,
  UpdateMultipleAdvertStatus,
} from "../../_service_api/ServiceApi";

const Table = ({ categories }) => {
  const { access, type } = useContext(ApiContext);

  const [bannerdata, setbannerdata] = useState([]);
  console.log(categories);

  const toggleVisibility = async (id, status) => {
    const formData = await new FormData();

    formData.append("Status", !status);
    let response = await updateAdvertiseStatus(id, formData);
    if (response) {
      alert("data add successfully");
      getdata();
    }
  };

  const getdata = async () => {
    const data = await getAllAdvertise();
    setbannerdata(data);
  };

  useEffect(() => {
    getdata();
  }, [categories]);
  console.log(bannerdata);

  const Delethandler = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");

    if (confirmDelete) {
      const response = await DeleteAdvertise(id);
      if (response) {
        alert("Successfully deleted");
        getdata();
      }
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  // Function to handle deletion of selected items
  const handleDeleteSelected = async () => {
    // Display confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete the selected items?"
    );

    if (isConfirmed) {
      await DeletMultipleAdvert(selectedItems);
      getdata();
    }
  };

  const handleUpdateStatusSelected = async (status) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to update the status of the selected items to ${status}?`
    );

    if (isConfirmed) {
      const response = await UpdateMultipleAdvertStatus(selectedItems, status);
      if (response) {
        getdata();
      }
    }
  };
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="card">
          
          <div className="card-header">
            <div className="card-title w-100 text-center">
              <h4 className="text-center">Advertisement</h4>
              <NavLink
                to={""}
                className={`btn ms-2 me-2 fw-bold btn-sm btn-success`}
                onClick={() => handleUpdateStatusSelected(true)}
              >
                Show
              </NavLink>
              <NavLink
                to={""}
                className={`btn ms-2 me-2 fw-bold btn-sm btn-primary`}
                onClick={() => handleUpdateStatusSelected(false)}
              >
                Hide
              </NavLink>
              {access === true || type === "admin" ? (
                <NavLink
                  className="fw-bold btn btn-danger btn-sm ms-2"
                  onClick={handleDeleteSelected}
                >
                  Delete
                </NavLink>
              ) : null}
            </div>
          </div>
          <div className="card-body p-0">
            <table className="table projects">
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>
                    <div class="form-check mb-0">
                      <input
                        type="checkbox"
                        name="tajasamachar"
                        style={{ border: "2px solid red" }}
                        class="form-check-input me-1"
                        onChange={() => {
                          if (selectedItems.length === bannerdata.length) {
                            setSelectedItems([]);
                          } else {
                            setSelectedItems(
                              bannerdata.map((item) => item._id)
                            );
                          }
                        }}
                        checked={selectedItems.length === bannerdata.length}
                        id="exampleCheck1"
                      />
                      <label for="exampleCheck1" className="mb-0">
                        All Select
                      </label>
                    </div>
                  </th>
                  <th style={{ width: "1%" }}>#</th>
                  <th style={{ width: "10%" }}>Location</th>
                  <th style={{ width: "7%" }}>Url</th>
                  <th style={{ width: "15%" }}>First Image</th>
                  <th style={{ width: "15%" }}>Second Image</th>
                </tr>
              </thead>
              <tbody>
                {bannerdata.map((item, key) => (
                  <tr
                    key={key}
                    className={
                      item.Status == true ? "table-light" : "table-primary"
                    }
                  >
                    <td>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="tajasamachar"
                          style={{ border: "2px solid red" }}
                          class="form-check-input"
                          checked={selectedItems.includes(item._id)}
                          onChange={() => handleCheckboxChange(item._id)}
                          id="exampleCheck1"
                        />
                      </div>
                    </td>
                    <td>
                      <strong>{key + 1}</strong>
                    </td>
                    <td>
                      <strong>{item.location}</strong>
                    </td>
                    <td>
                      <strong>{item.url}</strong>
                    </td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <img
                            alt="pic"
                            className="table-avatar"
                            src={item.Image1}
                          />
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <img
                            alt="pic"
                            className="table-avatar"
                            src={item.Image2}
                            width="80px"
                          />
                        </li>
                      </ul>
                    </td>

                    <td className="project-actions text-right">
                      <NavLink
                        to={""}
                        className={`btn me-3 fw-bold btn-sm ${
                          item.Status == true ? "btn-primary" : "btn-success"
                        }`}
                        onClick={() => toggleVisibility(item._id, item.Status)}
                      >
                        {/* {isVisible ? 'Hide' : 'Show'} */}
                        {item.Status == true ? "Hide" : "Show"}
                      </NavLink>

                      <NavLink
                        to={`/edit-advert/${item._id}`}
                        className="btn btn-info btn-sm fw-bold"
                      >
                        Edit
                      </NavLink>

                      {access === true || type === "admin" ? (
                        <NavLink
                          className="btn btn-danger btn-sm ms-2 fw-bold"
                          onClick={() => Delethandler(item._id)}
                        >
                          Delete
                        </NavLink>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Table;
